import React from "react"
import api from "api"
import { useAlerts, useLoaders, useResource } from "hooks"

const useVideos = ({ id, userId, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: videoId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: video,
    resources: videos,
    findOne: findVideo,
    findMany: findVideos,
    update: updateVideo,
    create: createVideo,
    save: saveVideo,
    destroy: deleteVideo,
    handleChange,
    handleChangePage,
    reload: reloadVideo,
    reloadMany: reloadVideos,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    setResources: setVideos,
  } = useResource({
    id: id,
    url: `/api/v1/scout/videos`,
    name: "video",
  })

  const findTags = async () => {
    const resp = await api.get(`/api/v1/videos/tags`)
    return resp.data
  }

  return {
    videoId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    video,
    videos,
    findVideo,
    findVideos,
    saveVideo,
    updateVideo,
    createVideo,
    deleteVideo,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    reloadVideo,
    reloadVideos,
    sortKey,
    sortDirection,
    handleSort,
    setVideos,
    findTags,
  }
}

export default useVideos
