import React, { useEffect, useContext, useState } from "react"
import { AppContext } from "context"
import { useUsers, useAlerts } from "hooks"
import { useHistory } from "react-router-dom"
import { SortableTableHeader, Placeholder } from "components"
import {
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core"
import PlayerItem from "containers/players/PlayerItem"

const TABLE_HEADERS = [
  {
    value: "name",
    label: "Full Name",
    sortable: true,
    hidden: false,
  },
  {
    value: "country",
    label: "Country",
    sortable: true,
    hidden: false,
  },
  {
    value: "positions",
    label: "Position",
    sortable: true,
    hidden: false,
  },
  {
    value: "preferred_foot",
    label: "Foot",
    sortable: true,
    hidden: false,
  },
  {
    value: "dob",
    label: "Date of Birth",
    sortable: true,
    hidden: false,
  },
  {
    value: "height",
    label: "HT",
    sortable: true,
    hidden: false,
  },
  {
    value: "weight",
    label: "Weight",
    sortable: true,
    hidden: false,
  },
]

const PlayerList = ({ teamId, clubId, ...props }) => {
  const history = useHistory()

  const { currentUser } = useContext(AppContext)
  const classes = useStyles()
  const { showAlertSuccess, showAlertError } = useAlerts()

  const {
    isLoading,
    isLoaded,
    isEmpty,
    user,
    users,
    findUsers,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    reloadUsers,
    sortKey,
    sortDirection,
    handleSort,
    handleChange,
  } = useUsers({ id: "me" })

  const handleClick = (user) => {
    history.push(`/players/${user.id}`)
  }

  useEffect(() => {
    if (clubId && teamId) {
      findUsers({
        "users.club_id": clubId,
        "users.team_id": teamId,
        page: 1,
      })
    }
  }, [teamId, clubId])

  return (
    <div>
      {!isEmpty ? (
        <TableContainer component={Paper} elevation={0} className={classes.table}>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                {TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
                  <SortableTableHeader
                    key={idx}
                    handleSort={handleSort}
                    value={`users.${header.value}`}
                    sortable={header.sortable}
                    sortKey={sortKey}
                    sortDirection={sortDirection}
                  >
                    {header.label}
                  </SortableTableHeader>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, idx) => (
                <PlayerItem key={idx} user={user} handleClick={handleClick} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={classes.empty}>
          <Placeholder
            title="No players"
            subtitle="There are no players added to this team."
          />
        </div>
      )}
    </div>
  )
}

export default PlayerList

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: 600,
  },
  table: {
    border: `1px solid ${theme.palette.common.tableBorder}`,
  },
  tableHeaderRow: {
    backgroundColor: theme.palette.background.table,
  },
}))
