import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useUsers, useAlerts } from "hooks"
import { IconButton, Typography, makeStyles, Button } from "@material-ui/core"
import { Page, LeftPanel, RightPanel, Subheader } from "components"
import VideoContainer from "containers/videos/VideoContainer"
import PlayerDetails from "containers/players/PlayerDetails"
import { Star, StarBorder } from "@material-ui/icons"

const PlayerShow = ({ ...props }) => {
  const history = useHistory()
  const classes = useStyles()

  const [listed, setListed] = useState(false)

  const id = props.match.params.id

  const {
    isLoading,
    user,
    users: favoriteUsers,
    findUser,
    findFavoriteUsers,
    addFavorites,
    removeFavorites,
  } = useUsers({
    id: id,
  })

  const { showAlertSuccess, showAlertError } = useAlerts()

  const handleFavoriteClick = async () => {
    if (listed) {
      await removeFavorites([user.id])
      setListed(false)
    } else {
      await addFavorites([user.id])
      setListed(true)
    }
  }

  const flagUrl = `https://flagcdn.com/w20/${user.country?.toLowerCase()}.png`

  let playerTitle = user.name
  if (user?.age) {
    playerTitle += ` (${user.age} y.o.)`
  }

  useEffect(() => {
    if (id) {
      findUser(id)
      findFavoriteUsers()
    }
  }, [id])

  useEffect(() => {
    if (id && favoriteUsers) {
      if (favoriteUsers.find((user) => user.id === parseInt(id))) {
        setListed(true)
      } else {
        setListed(false)
      }
    }
  }, [id, favoriteUsers])

  return (
    <>
      <Subheader
        imgIcon={flagUrl}
        title={isLoading ? "Loading..." : playerTitle}
        subtitle={user?.club?.name}
        secondaryActions={
          <div className={classes.secondaryActions}>
            <div className={classes.secondaryText}>
              <Typography
                variant="h6"
                color="textPrimary"
                className={classes.secondaryTitle}
              >
                {listed ? "Remove from List" : "Add to List"}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.secondarySubtitle}
              >
                Add players to your list to keep track of their stats.
              </Typography>
            </div>
            <div>
              <IconButton
                size="small"
                color="primary"
                onClick={handleFavoriteClick}
                className={classes.iconButton}
              >
                {listed ? (
                  <Star className={classes.icon} />
                ) : (
                  <StarBorder className={classes.icon} />
                )}
              </IconButton>
            </div>
          </div>
        }
      />
      <Page>
        <LeftPanel width={540}>
          <PlayerDetails user={user} />
        </LeftPanel>
        <RightPanel>
          <div className={classes.videoList}>
            <VideoContainer userId={user?.id} />
          </div>
        </RightPanel>
      </Page>
    </>
  )
}

export default PlayerShow

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  videoList: {
    padding: theme.spacing(2),
  },
  icon: {
    height: 32,
    width: 32,
  },
  iconButton: {
    margin: theme.spacing(0, 1),
  },
  secondaryActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  secondaryText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  secondarySubtitle: {
    color: theme.palette.common.grey,
  },
}))
