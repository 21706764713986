import React from "react"
import { Button, TableRow, TableCell, Link, makeStyles } from "@material-ui/core"
import { FlagIcon, TextCell } from "components"

const DiscoverPlayerItem = ({ user, handleClick, ...props }) => {
  const classes = useStyles()
  return (
    <TableRow className={classes.root}>
      <TextCell>
        <Link href={`/players/${user.id}`}>{user.name}</Link>
      </TextCell>
      <TextCell>
        <FlagIcon code={user.country} />
      </TextCell>
      <TextCell>{user?.positions?.join(",")}</TextCell>
      <TextCell>{user?.preferred_foot}</TextCell>
      <TextCell>{user.date_of_birth}</TextCell>
      <TableCell>
        <div className={classes.tableButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleClick(user)}
          >
            View Profile
          </Button>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default DiscoverPlayerItem

const useStyles = makeStyles((theme) => ({
  root: {},
  tableButtons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))
