import React, { useState } from "react"
import { useAlerts, useAuth } from "hooks"
import { Box, Button, Grid } from "@material-ui/core"
import { TextInput } from "components"
import { validatePassword } from "validations/users"
import { AuthScreen } from "components"

const ResetPassword = ({ ...props }) => {
  const [password, setPassword] = useState()
  const [passwordConfirmation, setPasswordConfirmation] = useState()

  const { showAlertError, showAlertSuccess } = useAlerts()

  const { resetPassword } = useAuth()

  const handleSubmit = async () => {
    let valid = validatePassword(password, passwordConfirmation)
    if (valid.isValid) {
      let resp = await resetPassword(password)
      if (resp && resp?.id) {
        window.location.href = "/"
      } else {
        showAlertError("Please enter a valid password")
      }
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleChange = ({ target }) => {
    setPassword(target.value)
  }

  const handleConfirmationChange = ({ target }) => {
    setPasswordConfirmation(target.value)
  }

  return (
    <div>
      <AuthScreen title="Create Password">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              name="password"
              handleChange={handleChange}
              type="password"
              placeholder="PASSWORD"
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              name="password_confirmation"
              handleChange={handleConfirmationChange}
              type="password"
              placeholder="CONFIRM PASSWORD"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              size="large"
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </AuthScreen>
    </div>
  )
}

export default ResetPassword
