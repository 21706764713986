import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import { FlagIcon, LineItem } from "components"

const ClubDetails = ({ club }) => {
  const classes = useStyles()

  return (
    <div>
      {club.image_url && (
        <center>
          <img src={club.image_url} className={classes.image} />
        </center>
      )}
      <div>
        <Typography variant="h5" className={classes.title}>
          GENERAL INFO
        </Typography>
        <LineItem label="Name" value={club.name} />
        <LineItem label="Country" value={<FlagIcon code={club.country} />} />
        <LineItem label="Location" value={club.city} />
        <LineItem label="Level" value={club.level} />
        <LineItem label="Main ground" value={club.main_ground} />
        <LineItem label="Training ground" value={club.training_ground} />
        <LineItem label="Club website" value={club.website} />
        <LineItem label="Club Representative" value={club.secretary} />
        <LineItem label="Contact phone" value={club.contact_phone} />
        <LineItem label="Contact email" value={club.contact_email} />
      </div>
    </div>
  )
}

export default ClubDetails

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: 160,
    width: "100%",
    height: "auto",
    margin: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))
