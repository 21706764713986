import React from "react"
import { TableCell, Typography } from "@material-ui/core"

const TextCell = ({ children, ...props }) => {
  return (
    <TableCell {...props}>
      <Typography variant="body2">{children ? children : "-"}</Typography>
    </TableCell>
  )
}

export default TextCell
