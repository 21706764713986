import React, { useState, useEffect } from "react"
import { useUsers } from "hooks"
import { SearchInput } from "components"
import { Search } from "@material-ui/icons"
import {
  Backdrop,
  Paper,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core"
import { useHistory } from "react-router"

const SearchAutocomplete = ({ ...props }) => {
  const classes = useStyles()
  const history = useHistory()
  const [keywords, setKeywords] = useState("")

  const { isEmpty, users, findUsers, setUsers } = useUsers({
    disableLoaders: true,
  })

  const handleKeywordChange = (ev) => {
    const value = ev.target.value
    if (value === "") {
      setKeywords(null)
      setUsers(null)
    } else {
      setKeywords(value)
    }
  }

  const handleKeyPress = (ev) => {
    if (ev.code === "Enter") {
      history.push(`/search?keywords=${keywords}`)
    }
  }

  const handleAdvancedSearchClick = () => {
    setUsers(null)
    setKeywords(null)
    history.push(`/search?keywords=${keywords}`)
  }

  const handlePlayerClick = (user) => {
    setUsers(null)
    setKeywords(null)
    history.push(`/players/${user.id}`)
  }

  useEffect(() => {
    if (keywords?.length > 0) {
      findUsers({
        keywords,
        page: 1,
        perPage: 10,
      })
    }
  }, [keywords])

  return (
    <div>
      <SearchInput
        placeholder="Enter a player"
        value={keywords}
        handleChange={handleKeywordChange}
        onKeyPress={handleKeyPress}
        className={classes.searchInput}
      />
      <Backdrop open={keywords?.length > 0} className={classes.backdrop}>
        {users?.length > 0 && (
          <Paper className={classes.searchResults}>
            <List className={classes.searchResultsList}>
              {users &&
                users.map((user, i) => (
                  <ListItem
                    key={i}
                    button
                    dense
                    onClick={() => handlePlayerClick(user)}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          color="textPrimary"
                          variant="body1"
                          className={classes.title}
                        >
                          {user.name} {user.age ? `(${user.age})` : ""}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          color="textPrimary"
                          variant="caption"
                          className={classes.subtitle}
                        >
                          {user?.club?.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                ))}
            </List>
            <Box p={2}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                startIcon={<Search />}
                onClick={handleAdvancedSearchClick}
              >
                Advanced Search
              </Button>
            </Box>
          </Paper>
        )}
      </Backdrop>
    </div>
  )
}

export default SearchAutocomplete

const useStyles = makeStyles((theme) => ({
  root: {},
  searchResults: {
    position: "fixed",
    top: 50,
    left: 235,
    width: 420,
    height: "auto",
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: theme.palette.common.white,
  },
}))
