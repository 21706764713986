import React from "react"
import { Link, Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { FlagIcon, LineItem } from "components"
import PlayerIcon from "assets/icons/player.png"

const ClubDetails = ({ user }) => {
  const classes = useStyles()

  const handleEmailPlayer = () => {
    window.open(`mailto:${user.email}`, "_blank")
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          GENERAL INFO
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <LineItem label="Last Name" value={user.first_name} />
        <LineItem label="First Name" value={user.last_name} />
        <LineItem
          label="Birth Date"
          value={
            <span>
              {user?.date_of_birth} ({user?.age}y.o.)
            </span>
          }
        />
        <LineItem label="Birth Country" value={<FlagIcon code={user?.country} />} />
        <LineItem
          label="Passport Country"
          value={<FlagIcon code={user?.passport_country} />}
        />
        <LineItem label="Preferred Positions" value={user?.positions?.join(", ")} />
        <LineItem label="Preferred Foot" value={user.preferred_foot} />
        <LineItem
          label="Current Club"
          value={
            <Link href={`/clubs/${user.club_id}`} className={classes.link}>
              {user.club?.name}
            </Link>
          }
        />
      </Grid>
      <Grid item xs={4}>
        <div className={classes.rightPanel}>
          <div>
            <img src={PlayerIcon} className={classes.image} />
          </div>
          <div>
            <div className={classes.label}>
              <Typography variant="body2" className={classes.labelText}>
                Height
              </Typography>
            </div>
            <div className={classes.value}>
              <Typography variant="body2" className={classes.valueText}>
                {user.height}cm
              </Typography>
            </div>

            <div className={classes.label}>
              <Typography variant="body2" className={classes.labelText}>
                Weight
              </Typography>
            </div>
            <div className={classes.value}>
              <Typography variant="body2" className={classes.valueText}>
                {user.weight}kg
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleEmailPlayer}
        >
          Email Player
        </Button>
      </Grid>
    </Grid>
  )
}

export default ClubDetails

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  labelText: {
    fontWeight: "bold",
  },
  rightPanel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  link: {
    textDecoration: "underline",
  },
}))
