import React from "react"
import { TextInput } from "components"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { Box, Button, Grid, Link, Typography, makeStyles } from "@material-ui/core"

const UserForm = ({ user, handleKeyPress, handleChange, handleSubmit }) => {
  const history = useHistory()
  const classes = useStyles()
  const handleResetClick = () => {
    history.push("/forgot_password")
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextInput
          border
          label="Email"
          required={true}
          name="email"
          value={user.email}
          onKeyPress={handleKeyPress}
          placeholder="EMAIL"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          label="Password"
          name="password"
          value={user.password}
          type="password"
          onKeyPress={handleKeyPress}
          placeholder="PASSWORD"
          handleChange={handleChange}
        />
        <div className={classes.resetPassword}>
          <Button
            onClick={handleResetClick}
            disableRipple
            className={classes.resetPasswordButton}
          >
            FORGOT YOUR PASSWORD?
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box my={1}>
          <Button
            fullWidth
            size="large"
            className={classes.button}
            color="secondary"
            onClick={handleSubmit}
            variant="contained"
          >
            Log In
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default UserForm

const useStyles = makeStyles((theme) => ({
  resetPasswordButton: {
    fontSize: 10,
  },
  resetPassword: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}))
