import React from "react"
import PropTypes from "prop-types"
import {
  Box,
  alpha,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  InputBase,
} from "@material-ui/core"
import { Cancel, Search } from "@material-ui/icons"
import { syntheticEvent } from "lib/utils"

const SearchInput = ({
  variant = "outlined",
  margin,
  name,
  label,
  value,
  placeholder = "Search...",
  onKeyPress,
  ...props
}) => {
  const classes = useStyles()

  const handleClearClick = () => {
    let e = syntheticEvent("", name)
    props.handleChange(e)
    if (props.handleClearClick) props.handleClearClick()
  }

  const handleChange = (ev) => {
    let { name, value } = ev.target
    props.handleChange(ev)
  }

  return (
    <Box my={1}>
      {label && (
        <Typography variant="body2" color="textSecondary">
          {label}
        </Typography>
      )}
      <InputBase
        className={classes.inputBase}
        type="text"
        fullWidth
        name={name}
        variant={variant}
        margin={margin}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        autoComplete="off"
        onChange={(e) => handleChange(e)}
        value={value}
      />
    </Box>
  )
}

SearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
}

export default SearchInput

export const useStyles = makeStyles((theme) => ({
  root: {},
  inputBase: {
    width: 340,
    "& input, & textarea": {
      backgroundColor: theme.palette.common.white,
      webkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      borderRadius: 2,
      padding: theme.spacing(0.5, 1),
      minHeight: 20,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: (props) => (props.noBorder ? null : "1px solid #ced4da"),
      fontSize: 15,
      "&:focus": {
        boxShadow: `${alpha("#999999", 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.light,
      },
    },
  },
  IconButton: {
    padding: "5px",
  },
  icon: {
    fontSize: "20px",
    color: "#888",
  },
}))
