import React from "react"
import { Footer } from "components"
import { makeStyles } from "@material-ui/core"

const Layout = ({ children, width, className, ...props }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.content}>{children}</div>
      <Footer />
    </div>
  )
}

export default Layout

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    minHeight: "calc(100vh - 54px)",
  },
}))
