import React from "react"
import { Button, IconButton, Typography, makeStyles } from "@material-ui/core"
import { Pagination, Notifications } from "components"

const Subheader = ({
  notifications,
  icon: Icon,
  imgIcon,
  title,
  subtitle,
  actions,
  secondaryActions,
  width,
  page,
  numPages,
  handleChangePage,
  handlePendingApprovalsClick,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Notifications
        notifications={notifications}
        onClick={handlePendingApprovalsClick}
      />
      <div className={classes.toolbar}>
        <div className={classes.titleBar}>
          {Icon && <Icon className={classes.icon} />}
          {imgIcon && <img src={imgIcon} className={classes.icon} />}
          <div className={classes.title}>
            <Typography color="textSecondary" variant="h5">
              {title}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body2"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          </div>
          <div>{actions && actions}</div>
        </div>
        <div>{secondaryActions && secondaryActions}</div>
        {page && (
          <Pagination
            page={page}
            numPages={numPages}
            handleChangePage={handleChangePage}
          />
        )}
      </div>
    </div>
  )
}
export default Subheader

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 48,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "space-between",
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.common.border}`,
  },
  toolbar: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    minWidth: 360,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  subtitle: {
    marginLeft: theme.spacing(1),
  },
  titleBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    margin: theme.spacing(0, 1),
  },
}))
