import React, { useState } from "react"
import { Pagination, Modal } from "components"
import { makeStyles } from "@material-ui/core"
import PlayerList from "containers/players/PlayerList"

const PlayersModal = ({
  open,
  club,
  team,
  handleClose,
  onComplete,
  page,
  numPages,
  handleChangePage,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={`${club?.name} - ${team?.name}`}
      maxWidth={"lg"}
    >
      <div className={classes.root}>
        <PlayerList clubId={club?.id} teamId={team?.id} onComplete={onComplete} />
        <div className={classes.pagination}>
          <Pagination
            page={page}
            numPages={numPages}
            handleChangePage={handleChangePage}
          />
        </div>
      </div>
    </Modal>
  )
}

export default PlayersModal

const useStyles = makeStyles((theme) => ({
  root: {},
  pagination: {
    display: "flex",
    flexScale: 1,
    justifyContent: "flex-end",
    margin: theme.spacing(2, 1),
  },
}))
