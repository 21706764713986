import React from "react"
import { ListItem, ListItemText, Typography, makeStyles } from "@material-ui/core"

const LineItem = ({ label, value, ...rest }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        <Typography variant="body2" className={classes.labelText}>
          {label}
        </Typography>
      </div>
      <div className={classes.value}>
        <Typography variant="body2" className={classes.valueText}>
          {value ? value : "-"}
        </Typography>
      </div>
    </div>
  )
}

export default LineItem

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  label: {
    minWidth: 160,
  },
  labelText: {
    fontWeight: "bold",
  },
}))
