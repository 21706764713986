import React, { useEffect, useState } from "react"
import { useAlerts, useAuth } from "hooks"
import { AuthScreen } from "components"
import SignupForm from "containers/auth/SignupForm"
import { validateUser } from "validations/users"
import { ChevronLeft } from "@material-ui/icons"
import { Button, Typography, makeStyles } from "@material-ui/core"
import { useHistory } from "react-router"
import moment from "moment"

const Signup = ({ ...props }) => {
  const { signupUser } = useAuth()
  const { showAlertError } = useAlerts()

  const [user, setUser] = useState({})

  const handleChange = (ev) => {
    const { name, value } = ev.target
    setUser({
      ...user,
      [name]: value,
    })
  }

  const [step, setStep] = useState(1)
  const history = useHistory()
  const classes = useStyles()

  const handleLoginClick = () => {
    history.push("/login")
  }

  const handleSubmit = async () => {
    if (step == 1) {
      setStep(2)
    } else if (step == 2) {
      const valid = validateUser(user)
      if (valid.isValid) {
        const resp = await signupUser(user)
        if (resp?.id) {
          if (resp.role == "club") {
            window.location.href = "/clubs"
          } else {
            window.location.href = "/favorites"
          }
        }
      } else {
        valid.messages.map((m) => showAlertError(m))
      }
    }
  }

  const handleBackClick = () => {
    setStep(1)
  }

  const handleKeyPress = (event) => {
    if (event.key == "Enter") handleSubmit()
  }

  useEffect(() => {
    console.log(user)
  }, [user])

  return (
    <div>
      <AuthScreen
        title={`${user?.role ? user.role : ""} Sign Up`}
        icon={step === 2 ? ChevronLeft : null}
        onClick={handleBackClick}
        actions={
          <>
            <Typography variant="body1" className={classes.actionText}>
              Already have an account?
            </Typography>
            <Button onClick={handleLoginClick} variant="contained" color="secondary">
              Log In
            </Button>
          </>
        }
      >
        <SignupForm
          user={user}
          step={step}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleSubmit={handleSubmit}
        />
      </AuthScreen>
    </div>
  )
}

export default Signup

const useStyles = makeStyles((theme) => ({
  actionText: {
    fontSize: 12,
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
  },
}))
