import React, { useState } from "react"
import { Modal } from "components"
import { Button, makeStyles } from "@material-ui/core"
import PendingPlayersList from "./PendingPlayersList"

const PendingPlayersModal = ({ open, club, handleClose, onComplete, ...props }) => {
  const classes = useStyles()

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={`${club.name} - Player Approvals`}
      maxWidth={"lg"}
    >
      <div className={classes.root}>
        <PendingPlayersList clubId={club?.id} onComplete={onComplete} />
      </div>
    </Modal>
  )
}

export default PendingPlayersModal

const useStyles = makeStyles((theme) => ({
  root: {},
}))
