import React, { useEffect, useState } from "react"
import api from "api"
import { useAlerts, useLoaders } from "hooks"
import { useHistory } from "react-router"

/* useResource
  A base hook that allows for CRUD operations of a REST API that follows
  standard REST patterns of GET POST PUT and DELETE to create, update, create and
  destroy objects.

  @param id - The resource ID to auto fetch
  @param url - The API endpoint. The is set dynamically using setEndpoint
  @param name - The name of the resource needed when using POST and PUT
*/

const useResource = ({ url = "/", name, disableLoaders = false, ...props }) => {
  const history = useHistory()
  const { isLoading, showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess, showAlertWarning } = useAlerts()

  const [isLoaded, setIsLoaded] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [id, setId] = useState(props.id)
  const [resource, setResource] = useState({ id: props.id })
  const [resources, setResources] = useState([])
  const [meta, setMeta] = useState({})
  const [params, setParams] = useState({})
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(20)
  const [numPages, setNumPages] = useState(1)

  const [sortKey, setSortKey] = useState(`${name}s.id`)
  const [sortDirection, setSortDirection] = useState("desc")

  const [totalCount, setTotalCount] = useState(0)
  const [errors, setErrors] = useState([])

  const findOne = async (id) => {
    if (!id) showAlertError("No ID was provided")
    try {
      showLoading()
      //setIsLoaded(false)
      setId(id)
      const res = await api.get(`${url}/${id}`)
      setResource(res.data)
      setMeta(res.meta)
      setIsLoaded(true)
      hideLoading()
      return res.data
    } catch (e) {
      handleErrors(e)
    }
    hideLoading()
  }

  const findMany = async (params, page = 1, loadMore = false) => {
    try {
      if (!disableLoaders) {
        showLoading()
      }
      setParams(params)
      setPage(page)
      if (!loadMore) {
        setIsLoaded(false)
      }
      const res = await api.get(url, {
        params: {
          ...params,
          page,
        },
      })
      setAllResources(res, loadMore)
    } catch (e) {
      handleErrors(e)
    } finally {
      hideLoading()
    }
  }

  const loadMore = () => {
    let nextPage = page + 1
    let appendResults = true
    findMany(params, nextPage, appendResults)
  }

  const create = async (resource, files, showLoaders = true) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
      let formData = new FormData()
      for (const item in resource) {
        formData.append(`${name}[${item}]`, resource[item])
      }
      if (files && Object.keys(files).length) {
        for (const key in files) {
          formData.append(`${name}[${key}]`, files[key].file)
        }
      }
      const res = await api.post(`${url}`, formData, config)
      if (res.data && res.data.id) {
        setResource(res.data)
        setIsLoaded(true)
        setId(res.data.id)
      }
      return res.data
    } catch (e) {
      console.log(e)
      showAlertError("There was an issue saving")
      handleErrors(e)
    } finally {
      hideLoading()
    }
  }

  const update = async (resource) => {
    setId(resource.id)
    try {
      if (!disableLoaders) {
        showLoading()
      }
      const res = await api.put(`${url}/${resource.id}`, {
        [name]: resource,
      })
      return res.data
    } catch (e) {
      showAlertError("There was an issue updating")
      handleErrors(e)
    } finally {
      hideLoading()
    }
  }

  const destroy = async (resource) => {
    try {
      showLoading()
      const res = await api.delete(`${url}/${resource.id}`)
      setResource({ data: {} })
    } catch (e) {
      handleErrors(e)
    } finally {
      hideLoading()
    }
  }

  const paginate = (page) => findMany(params, page)

  const uploadFile = async (file, attributeName, _id) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
      let formData = new FormData()
      formData.append(`${name}[${attributeName}]`, file)
      const res = await api.post(`${url}/${_id || id}/upload_file`, formData, config)
    } catch (e) {
      showAlertError("There was an issue uploading")
      handleErrors(e)
    }
  }

  const deleteFile = async (type) => {
    showLoading()
    await api.post(`${url}/${id}/delete_file`, {
      type,
    })
    hideLoading()
  }

  const handleChange = (ev) => {
    const { name } = ev.target
    const value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value
    setResource({
      ...resource,
      [name]: value,
    })
  }

  const handleErrors = (e) => {
    hideLoading()
    setIsLoaded(false)
    setErrors(e)
    console.error("useResource Error:", e)
    if (e?.status == 401) {
      showAlertError("Please Sign In to continue")
      localStorage.removeItem("token")
      setTimeout(() => (window.location.href = "/login"), 1000)
    }
    return false
  }

  const reloadOne = () => findOne(id)
  const reloadMany = () => findMany(params)

  const handleSort = (sortBy) => {
    sortDirection == "asc" ? setSortDirection("desc") : setSortDirection("asc")
    setSortKey(sortBy)
  }

  const setAllResources = (res, loadMore = false) => {
    const { data, meta } = res || {}
    if (data) {
      if (!loadMore) {
        setResources(data)
      } else {
        setResources([...resources, ...data])
      }
      if (meta) {
        const { page, per_page, num_pages, total_count } = meta

        setMeta(meta)
        setPage(page)
        setPerPage(per_page)
        setNumPages(num_pages)
        setTotalCount(total_count)
      }
      setIsEmpty(res?.data?.length > 0 ? false : true)
      setIsLoaded(true)
      return res.data
    }
  }

  return {
    id,
    isLoading,
    isLoaded,
    isEmpty,
    resource,
    resources,
    setResource,
    setResources,
    errors,
    meta,
    findOne,
    findMany,
    update,
    create,
    destroy,
    paginate,
    loadMore,
    handleChange,
    uploadFile,
    deleteFile,
    params,
    page,
    perPage,
    numPages,
    totalCount,
    reloadOne,
    reloadMany,
    sortKey,
    sortDirection,
    handleSort,
    setAllResources,
  }
}

export default useResource
