import React, { useState, useEffect } from "react"

// Helper hook to run a function on Enter key press
const useVideo = () => {  
  const [activeVideo, setActiveVideo] = useState()
  const [videoOpen, setVideoOpen] = useState(false)

  const playVideo = (video) => {    
    setActiveVideo(video)
    setVideoOpen(true)
  }

  const stopVideo = () => {
    setActiveVideo()
    setVideoOpen(false)
  }

  return {
    src: activeVideo,
    activeVideo,
    setActiveVideo,
    playVideo,
    stopVideo,
    videoOpen,
  }
}

export default useVideo
