import React from "react"
import { Autosuggest, TextInput } from "components"
import { Button, Grid, makeStyles, Typography } from "@material-ui/core"
import { HEIGHTS, WEIGHTS, AGE_RANGES, POSITIONS } from "lib/constants"
import { COUNTRIES } from "lib/constants/countries"
import Filter from "assets/icons/filter.svg"

const SearchForm = ({ query, handleChange, handleSearch, handleResetFilters }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>      
        <Grid item xs={12}>
          <Autosuggest
            label="Country"
            name="country"
            value={query?.country}
            handleChange={handleChange}
            placeholder="Country"
            options={COUNTRIES}
          />
        </Grid>
        <Grid item xs={12}>
          <Autosuggest
            label="Position"
            name="positions"
            value={query?.position}
            handleChange={handleChange}
            placeholder="Position"
            options={POSITIONS}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            label="Age Range"
            placeholder="Min"
            name="age_min"
            value={query?.age_min}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            className={classes.maxInput}
            placeholder="Max"
            name="age_max"
            value={query?.age_max}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            label="Height (cm)"
            placeholder="Min"
            name="height_min"
            value={query?.height_min}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            className={classes.maxInput}
            placeholder="Max"
            name="height_max"
            value={query?.height_max}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            label="Weight (kg)"
            placeholder="Min"
            name="weight_min"
            value={query?.weight_min}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            type="number"
            className={classes.maxInput}
            placeholder="Max"
            name="weight_max"
            value={query?.weight_max}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            startIcon={<img src={Filter} alt="filter" />}
            color="secondary"
            variant="contained"
            onClick={handleSearch}
          >
            APPLY FILTERS
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth            
            color="secondary"
            variant="outlined"
            onClick={handleResetFilters}
          >
            RESET FILTERS
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default SearchForm

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  maxInput: {
    marginTop: 20,
  },
}))
