import React, { useContext, useEffect, useState } from "react"
import { useAlerts, useLoaders } from "hooks"
import api from "api"
import { AppContext } from "context"

const useAuth = (props) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const { authenticated, setAuthenticated, currentUser, setCurrentUser, setToken } =
    useContext(AppContext)

  const [user, setUser] = useState({})

  const handleChange = (ev) => {
    const { name } = ev.target
    let value = ev.target.type === "checkbox" ? ev.target.checked : ev.target.value
    setUser({
      ...user,
      [name]: value,
    })
  }

  const fetchMe = async () => {
    try {
      let resp = await api.get("/api/v1/users/me")
      if (resp && resp.data) {
        setCurrentUser(resp.data)
      }
      return resp.data
    } catch (e) {
      console.log("HERE")
      if (e?.status === 401) {
        showAlertError("Please sign in to continue.")
        localStorage.removeItem("token")
        window.location.href = "/"
      }
    }
  }

  const updateMe = async (user) => {
    showLoading()
    let resp = await api.put(`/api/v1/users/${user.id}`, {
      user: user,
    })
    setCurrentUser(resp.data)
    hideLoading()
    return resp.data
  }

  const loginUser = async (user) => {
    try {
      showLoading()
      let resp = await api.post("/api/v1/login", {
        user: user,
      })
      hideLoading()
      if (resp.data && ["club", "scout"].includes(resp.data.role)) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        await setTokens(resp.data)
        if (resp.data.role === "club") {
          window.location.href = "/clubs"
        } else if (resp.data.role === "scout") {
          window.location.href = "/favorites"
        }
        return resp.data
      } else {
        showAlertError("Only club and scout accounts may sign in.")
        return false
      }
    } catch (e) {
      showAlertError("Your email or password is incorrect")
      hideLoading()
    }
  }

  const signupUser = async (user) => {
    try {
      showLoading()
      let resp = await api.post("/api/v1/signup", {
        user: user,
      })
      hideLoading()
      if (resp.data) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      } else {
        return false
      }
    } catch (e) {
      showAlertError("A user with this email may already exist")
      hideLoading()
    }
  }

  const resetPassword = async (password, passwordConfirmation) => {
    try {
      showLoading()
      let resp = await api.post("/api/v1/reset_password", {
        user: {
          password: password,
          password_confirmation: passwordConfirmation,
        },
      })
      hideLoading()
      if (resp.data && ["club", "scout"].includes(resp.data.role)) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      } else {
        showAlertError('Only club and scout accounts may sign in.')
        return false
      }
    } catch (e) {
      showAlertError("There was an error resetting your password")
      hideLoading()
    }
  }

  const verifyPin = async (pin, email) => {
    let verified = false
    try {
      showLoading()
      let resp = await api.post("/api/v1/verify_pin", {
        user: {
          pin,
          email,
        },
      })
      hideLoading()
      if (resp.data?.id) {
        setCurrentUser(resp.data)
        setAuthenticated(true)
        setTokens(resp.data)
        return resp.data
      } else {
        return false
      }
      return verified
    } catch (e) {
      hideLoading()
      return verified
    }
  }

  const forgotPassword = async (email) => {
    try {
      showLoading()
      let resp = await api.post("/api/v1/forgot_password", {
        user: {
          email,
        },
      })
      setCurrentUser(resp.data)
      hideLoading()
      return resp.data
    } catch (e) {
      showAlertError("A user with that email could not be found")
      hideLoading()
    }
  }

  const logoutUser = () => {
    setCurrentUser(null)
    setAuthenticated(null)
    localStorage.removeItem("token")
    window.location.href = "/"
  }

  const authenticateFromToken = (token) => {
    if (token) {
      setAuthenticated(true)
      setToken(token)
    } else {
      return false
    }
  }

  const setTokens = (user) => {
    setToken(user.token)
    localStorage.setItem("token", user.token)
  }

  useEffect(() => {
    if (currentUser && !authenticated) {
      setTokens(currentUser)
      setAuthenticated(true)
    }
  }, [currentUser])

  return {
    user,
    authenticated,
    currentUser,
    handleChange,
    fetchMe,
    updateMe,
    loginUser,
    signupUser,
    logoutUser,
    verifyPin,
    resetPassword,
    forgotPassword,
    authenticateFromToken,
  }
}

export default useAuth
