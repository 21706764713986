import React from "react"
import api from "api"
import { useAlerts, useLoaders, useResource } from "hooks"

const useSearch = ({ id, ...props }) => {
  const {
    resourceId: userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: user,
    resources: users,
    findOne: findUser,
    findMany: findUsers,
    handleChange,
    handleChangePage,
    reload: reloadUser,
    reloadMany: reloadUsers,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    setResources: setUsers,
  } = useResource({
    id: id,
    url: "/api/v1/scout/search",
    name: "user",
  })

  return {
    userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    user,
    users,
    findUser,
    findUsers,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    reloadUser,
    reloadUsers,
    sortKey,
    sortDirection,
    handleSort,
    setUsers,
  }
}

export default useSearch
