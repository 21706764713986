import React from "react"
import api from "api"
import { useAlerts, useLoaders, useResource } from "hooks"
import { validateUser } from "validations/users"

const useUsers = ({ id, disableLoaders, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: user,
    resources: users,
    findOne: findUser,
    findMany: findUsers,
    update: updateUser,
    create: createUser,
    save: saveUser,
    destroy: deleteUser,
    handleChange,
    handleChangePage,
    uploadFile,
    deleteFile,
    reload: reloadUser,
    reloadMany: reloadUsers,
    paginate,
    query,
    page,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    setResources: setUsers,
    setAllResources: setAllUsers,
  } = useResource({
    id: id,
    url: "/api/v1/scout/users",
    name: "user",
    disableLoaders: disableLoaders,
  })

  const updateManyUsers = async (users) => {
    let newUsers = users.map((user) => ({
      id: user.id,
      club_approved: user.club_approved,
      team_id: user.team_id,
    }))
    try {
      showLoading()
      const resp = await api.post("/api/v1/scout/users/update_many", {
        users: newUsers,
      })
      return resp?.data
    } catch (e) {
      console.log(e)
    } finally {
      hideLoading()
    }
  }

  const findFavoriteUsers = async (params) => {
    try {
      showLoading()
      const resp = await api.get(`/api/v1/scout/users/favorites`, {
        params: params,
      })
      setAllUsers(resp)
      return resp?.data
    } catch (e) {
      showAlertError("There was an error finding users")
    } finally {
      hideLoading()
    }
  }

  const findBrowseUsers = async (userId) => {
    try {
      showLoading()
      const resp = await api.get(`/api/v1/scout/users/browse`)
      setAllUsers(resp)
      return resp?.data
    } catch (e) {
      showAlertError("There was an error finding users")
    } finally {
      hideLoading()
    }
  }

  const findPendingUsers = async (userId) => {
    try {
      showLoading()
      const resp = await api.get(`/api/v1/scout/users/pending_approvals`)
      setAllUsers(resp)
      return resp?.data
    } catch (e) {
      showAlertError("There was an error approving the club")
    } finally {
      hideLoading()
    }
  }

  const addFavorites = async (userIds) => {
    try {
      showLoading()
      const resp = await api.post("/api/v1/scout/users/add_favorites", {
        user_ids: userIds,
      })
      return resp?.data
    } catch (e) {
      console.log(e)
    } finally {
      hideLoading()
    }
  }

  const removeFavorites = async (userIds) => {
    try {
      showLoading()
      const resp = await api.post("/api/v1/scout/users/remove_favorites", {
        user_ids: userIds,
      })
      return resp?.data
    } catch (e) {
      console.log(e)
    } finally {
      hideLoading()
    }
  }

  return {
    userId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    currentUser: user,
    user,
    users,
    findUser,
    findUsers,
    findPendingUsers,
    findFavoriteUsers,
    findBrowseUsers,
    addFavorites,
    removeFavorites,
    saveUser,
    updateUser,
    updateManyUsers,
    createUser,
    deleteUser,
    uploadFile,
    deleteFile,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    reloadUser,
    reloadUsers,
    sortKey,
    sortDirection,
    handleSort,
    setUsers,
  }
}

export default useUsers
