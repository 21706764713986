import React from "react"
import {
  Paper,
  IconButton,
  Button,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core"
import Logo from "assets/logo.svg"
import { useHistory } from "react-router"

const AuthScreen = ({ title, icon: Icon, onClick, children, actions }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleLogoClick = () => {
    history.push("/")
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <center>
          <Button onClick={handleLogoClick}>
            <img src={Logo} className={classes.logo} />
          </Button>
        </center>
        <Paper elevation={0}>
          <div className={classes.container}>
            <div className={classes.header}>
              {Icon && (
                <IconButton
                  size="small"
                  onClick={onClick}
                  className={classes.iconButton}
                >
                  <Icon className={classes.icon} />
                </IconButton>
              )}
              <Typography
                variant="subtitle1"
                className={classes.title}
                color="primary"
              >
                {title}
              </Typography>
            </div>
            {children}
          </div>
        </Paper>
        {actions && <div className={classes.actions}>{actions}</div>}
      </Container>
    </div>
  )
}

export default AuthScreen

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "calc(100vh - 60px)",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 240,
    marginBottom: theme.spacing(2),
  },
  title: {
    textTransform: "uppercase",
  },
  container: {
    padding: theme.spacing(3),
  },
  icon: {
    color: theme.palette.primary.main,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}))
