export const NODE_ENV = process.env.NODE_ENV
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID

export const THUMBNAIL_HEIGHT = 140
export const THUMBNAIL_WIDTH = 270

export const USER_ROLES = [
  { value: "club", label: "Club" },
  { value: "scout", label: "Scout" },
]

export const USER_APPROVALS = [
  { value: true, label: "APPROVE" },
  { value: false, label: "DENY" },
]

export const PREFERRED_FOOT = [
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
]

export const TEAM_GENDERS = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
]

export const TEAM_AGE_GROUPS = [
  { value: "Age 17", label: "Age 17+" },
  { value: "Age 21", label: "Age 21+" },
]

export const TEAM_LEAGUES = [
  { value: "League A", label: "League A" },
  { value: "League B", label: "League B" },
]

export const TEAM_DIVISIONS = [
  { value: "Divion A", label: "Division A" },
  { value: "Division B", label: "Division B" },
]

export const AGE_RANGES = [
  { value: "age_17", label: "Age 17+" },
  { value: "age_21", label: "Age 21+" },
]

export const HEIGHTS = [{ value: 180, label: "180cm" }]

export const WEIGHTS = [
  { value: 85, label: "85kg" },
  { value: 90, label: "90kg" },
]

export const SEARCH_BULK_ACTIONS = [
  { value: "add_favorites", label: "List Players" },
  { value: "remove_favorites", label: "Unlist Players" },
]

export const TEAM_BULK_ACTIONS = [
  { value: "edit_teams", label: "Edit Teams" },
  { value: "delete_teams", label: "Delete Teams" },
]

export const FAVORITE_BULK_ACTIONS = [
  { value: "remove_favorites", label: "Remove from List" },
]

export const POSITIONS = [
  // goalkeeper
  { value: "GK", label: "Goalkeeper" },
  // defender
  { value: "LB", label: "Left Back" },
  { value: "CB", label: "Centre Back" },
  { value: "RB", label: "Right Back" },
  { value: "LWB", label: "Left Wing Back" },
  { value: "RWB", label: "Right Wing Back" },
  // midfielder
  { value: "CDM", label: "Defensive Midfielder" },
  { value: "CM", label: "Centre Midfielder" },
  { value: "CAM", label: "Attacking Midfielder" },
  { value: "LM", label: "Left Midfielder" },
  { value: "RM", label: "Right Midfielder" },
  // forward
  { value: "LW", label: "Left Winger" },
  { value: "RW", label: "Right Winger" },
  { value: "CF", label: "Centre Forward" },
  { value: "ST", label: "Striker" },
]
