import React, { useEffect, useContext, useState } from "react"
import { AppContext } from "context"
import { useUsers, useAlerts } from "hooks"
import { SortableTableHeader, TableHeader } from "components"
import {
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
} from "@material-ui/core"
import PendingUserItem from "containers/players/PendingUserItem"
import Pagination from "@material-ui/lab/Pagination"

const TABLE_HEADERS = [
  {
    value: "name",
    label: "Full Name",
    sortable: true,
    hidden: false,
  },
  {
    value: "country",
    label: "Country",
    sortable: true,
    hidden: false,
  },
  {
    value: "dob",
    label: "Date of Birth",
    sortable: true,
    hidden: false,
  },
  {
    value: "club_approved",
    label: "Approval",
    sortable: true,
    hidden: false,
  },
  {
    value: "team_id",
    label: "Add to Team",
    sortable: false,
    hidden: false,
  },
]

const PendingPlayersList = ({ clubId, onComplete, ...props }) => {
  const classes = useStyles()
  const sortKey = "users.first_name"
  const sortDirection = "asc"
  const { showAlertSuccess, showAlertError } = useAlerts()

  const {
    isLoading,
    users,
    findUsers,
    setUsers,
    findPendingUsers,
    updateManyUsers,
    page,
    perPage,
    handleSort,
  } = useUsers({})

  const handleChange = (ev, userId) => {
    const { name, value } = ev.target
    let newUsers = users.map((user) => {
      if (user.id === userId) {
        user[name] = value
      }
      return user
    })
    setUsers(newUsers)
  }

  const handleSubmit = async () => {
    const resp = await updateManyUsers(users)
    showAlertSuccess("Players updated")
    if (onComplete) {
      onComplete()
    }
  }

  useEffect(() => {
    findPendingUsers()
  }, [clubId])

  return (
    <div>
      <TableContainer component={Paper} elevation={0} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
                <SortableTableHeader
                  key={idx}
                  handleSort={handleSort}
                  value={`users.${header.value}`}
                  sortable={header.sortable}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                >
                  {header.label}
                </SortableTableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, idx) => (
              <PendingUserItem key={idx} user={user} handleChange={handleChange} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.buttons}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default PendingPlayersList

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.background.table,
  },
  caption: {
    fontWeight: 600,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    margin: theme.spacing(2),
  },
}))
