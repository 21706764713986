import React, { useEffect, useState } from "react"
import { Pagination, Modal, TextInput } from "components"
import { useVideos } from "hooks"
import { Box, makeStyles } from "@material-ui/core"
import VideoGallery from "containers/videos/VideoGallery"
import VideoTags from "containers/videos/VideoTags"

const VideoSearchModal = ({
  open,
  keywords: searchKeywords,
  handleClose,
  ...props
}) => {
  const [keywords, setKeywords] = useState(searchKeywords)
  const [tags, setTags] = useState([])
  const [activeTags, setActiveTags] = useState([])
  const classes = useStyles()

  const { isLoading, videos, findVideos, page, numPages, paginate, findTags } =
    useVideos({})

  const handleChange = (ev) => {
    setKeywords(ev.target.value)
    if (ev.target.value === "") {
      setActiveTags([])
      findVideos({
        keywords: "",
        page: 1,
      })
    }
  }

  const handleTagClick = (tag) => {
    let newTags = []
    if (activeTags.includes(tag)) {
      newTags = activeTags.filter((t) => t !== tag)
    } else {
      newTags = [...activeTags, tag]
    }
    setActiveTags(newTags)
    findVideos({
      keywords: newTags.join(" "),
      page: 1,
    })
  }

  const handleFindTags = async () => {
    const resp = await findTags(keywords)
    setTags(resp)
  }

  const handleKeyPress = (ev) => {
    if (ev.code === "Enter") {
      findVideos({
        keywords,
        page: 1,
        perPage: 20,
      })
    }
  }

  useEffect(() => {
    setKeywords(searchKeywords)
    setActiveTags(searchKeywords.split(" ") || [])
  }, [searchKeywords])

  useEffect(() => {
    setKeywords(activeTags.join(" "))
  }, [activeTags])

  useEffect(() => {
    if (open) {
      handleFindTags()
      findVideos({
        keywords: keywords,
      })
    }
  }, [open])

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={"SEARCH VIDEOS"}
      maxWidth={"lg"}
      padding={2}
    >
      <div className={classes.root}>
        <div className={classes.searchBar}>
          <Box my={2} width={280}>
            <TextInput
              placeholder={"SEARCH KEYWORD(S)"}
              value={keywords}
              handleChange={handleChange}
              onKeyPress={handleKeyPress}
            />
          </Box>
          <Pagination page={page} numPages={numPages} handleChangePage={paginate} />
        </div>
        <VideoTags
          tags={tags}
          activeTags={activeTags}
          handleClick={handleTagClick}
        />
        <VideoGallery 
          renderUserName 
          videos={videos} 
        />
      </div>
    </Modal>
  )
}

export default VideoSearchModal

const useStyles = makeStyles((theme) => ({
  root: {},
  searchBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))
