import React, { useState } from "react"
import { IconButton, TableRow, TableCell, makeStyles } from "@material-ui/core"
import { Autosuggest, TextInput } from "components"
import { CheckCircle } from "@material-ui/icons"
import { TEAM_GENDERS, TEAM_AGE_GROUPS } from "lib/constants"

const TeamEditRow = ({ team: propsTeam, handleSubmit, ...props }) => {
  const classes = useStyles()

  const [team, setTeam] = useState(propsTeam)

  const handleChange = (e) => {
    const { name, value } = e.target
    setTeam({ ...team, [name]: value })
  }

  return (
    <TableRow className={classes.root}>
      <TableCell className={classes.cell}>
        <IconButton
          className={classes.iconButton}
          onClick={() => handleSubmit(team)}
        >
          <CheckCircle className={classes.icon} />
        </IconButton>
        <TextInput
          noBorder
          name="name"
          value={team.name}
          handleChange={handleChange}
          placeholder="ADD NEW TEAM"
        />
      </TableCell>
      <TableCell>
        <Autosuggest
          noBorder
          name="gender"
          placeholder="SELECT GENDER ▼"
          options={TEAM_GENDERS}
          value={team?.gender}
          handleChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <Autosuggest
          noBorder
          name="age_group"
          placeholder="SELECT AGE GROUP ▼"
          options={TEAM_AGE_GROUPS}
          value={team?.age_group}
          handleChange={handleChange}
        />
      </TableCell>
      <TableCell>
        <TextInput
          noBorder
          name="league"
          value={team.league}
          handleChange={handleChange}
          placeholder="LEAGUE"
        />
      </TableCell>
      <TableCell>
        <TextInput
          noBorder
          name="division"
          value={team.division}
          handleChange={handleChange}
          placeholder="DIVISION"
        />
      </TableCell>
      <TableCell align="right"></TableCell>
    </TableRow>
  )
}

export default TeamEditRow

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.table,
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    height: 18,
    width: 18,
  },
}))
