import React from "react"
import { Button, Grid, Typography, makeStyles } from "@material-ui/core"
import { Autosuggest, TextInput } from "components"
import { COUNTRIES } from "lib/constants/countries"

const ClubForm = ({ club, handleSubmit, handleChange }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.title}>
          GENERAL INFO
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.name}
          label="Name"
          name="name"
          placeholder="Name"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Autosuggest
          options={COUNTRIES}
          value={club.country}
          label="Country"
          name="country"
          placeholder="Country"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.city}
          label="Location"
          name="city"
          placeholder="Location"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.level}
          label="Level"
          name="level"
          placeholder="Level"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.main_ground}
          label="Main ground address"
          name="main_ground"
          placeholder="Main ground address"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.training_ground}
          label="Training ground"
          name="training_ground"
          placeholder="Training ground address"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.secretary}
          label="Secretary"
          name="secretary"
          placeholder="Secretary"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.website}
          label="Club website"
          name="website"
          placeholder="Club website"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.contact_email}
          label="Contact email"
          name="contact_email"
          placeholder="Contact email"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextInput
          value={club.contact_phone}
          label="Contact phone"
          name="contact_phone"
          placeholder="Contact phone"
          handleChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          fullWidth
          onClick={handleSubmit}
          color="secondary"
          variant="contained"
          className={classes.button}
        >
          Save Details
        </Button>
      </Grid>
    </Grid>
  )
}

export default ClubForm

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}))
