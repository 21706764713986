import React, { useEffect, useContext, useState } from "react"
import { AppContext } from "context"
import { useTeams, useSelected, useAlerts } from "hooks"
import { useHistory } from "react-router-dom"
import {
  Autosuggest,
  ButtonLoader,
  Pagination,
  SortableTableHeader,
  TableHeader,
} from "components"
import {
  Paper,
  Button,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
} from "@material-ui/core"
import TeamItem from "containers/teams/TeamItem"
import TeamAddRow from "containers/teams/TeamAddRow"
import TeamEditRow from "containers/teams/TeamEditRow"
import { validateTeam } from "validations/teams"
import { TEAM_BULK_ACTIONS } from "lib/constants"

const TABLE_HEADERS = [
  {
    value: "name",
    label: "Name",
    sortable: true,
    hidden: false,
  },
  {
    value: "gender",
    label: "Gender",
    sortable: true,
    hidden: false,
  },
  {
    value: "age_group",
    label: "Age Group",
    sortable: true,
    hidden: false,
  },
  {
    value: "league",
    label: "League",
    sortable: true,
    hidden: false,
  },
  {
    value: "division",
    label: "Division",
    sortable: true,
    hidden: false,
  },
]

const TeamList = ({ editing, setEditing, handleClick, ...props }) => {
  const history = useHistory()

  const { selected, selectedIds, handleSelect, handleClear } = useSelected()

  const [teamId, setTeamId] = useState()
  const { currentUser } = useContext(AppContext)
  const classes = useStyles()
  const { showAlertSuccess, showAlertError } = useAlerts()

  const [activeAction, setActiveAction] = useState(null)
  const [editingTeam, setEditingTeam] = useState(false)

  const {
    isLoading,
    team,
    teams,
    findTeams,
    createTeam,
    updateTeam,
    deleteTeams,
    paginate,
    query,
    page,
    numPages,
    reloadTeams,
    sortKey,
    sortDirection,
    handleSort,
    handleChange,
    setTeam,
  } = useTeams({
    id: teamId,
    clubId: currentUser?.club_id,
  })

  const handleBulkAction = async () => {
    if (!activeAction) {
      showAlertError("Please select a bulk action")
      return
    }

    if (!selectedIds?.length > 0) {
      showAlertError("Select at least 1 team")
      return
    }
    let resp
    switch (activeAction) {
      case "edit_teams":
        setEditingTeam(true)
        return
      case "delete_teams":
        await deleteTeams(selectedIds)
        showAlertSuccess("Teams have been deleted")
        setActiveAction(null)
        handleClear()
        reloadTeams()
        return
    }
  }

  const handleCreateTeam = async () => {
    let valid = validateTeam(team)
    if (valid.isValid) {
      await createTeam(team)
      reloadTeams()
      setTeam({})
      setEditing(false)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleUpdateTeam = async (team) => {
    let valid = validateTeam(team)
    if (valid.isValid) {
      await updateTeam(team)
      handleSelect(team)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  useEffect(() => {
    findTeams({
      ...query,
      sort_key: sortKey,
      sort_direction: sortDirection,
      page: page,
    })
  }, [query, page, sortKey, sortDirection])

  useEffect(() => {
    // Reset editing if no rows selected
    if (selectedIds?.length == 0) {
      setEditingTeam(false)
    }
  }, [selectedIds])

  return (
    <div>
      <TableHeader>
        <div className={classes.bulkActions}>
          <Autosuggest
            value={activeAction}
            options={TEAM_BULK_ACTIONS}
            placeholder={"Bulk actions"}
            handleChange={(ev) => setActiveAction(ev.target.value)}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleBulkAction}
            className={classes.bulkActionButton}
          >
            Apply
          </Button>
        </div>
        <div className={classes.pagination}>
          <Pagination page={page} numPages={numPages} handleChangePage={paginate} />
        </div>
      </TableHeader>
      <TableContainer component={Paper} elevation={0} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeader}>
              {TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
                <SortableTableHeader
                  key={idx}
                  handleSort={handleSort}
                  value={`teams.${header.value}`}
                  sortable={header.sortable}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                >
                  {header.label}
                </SortableTableHeader>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teams.map((team, idx) => {
              const isSelected = selectedIds.includes(team.id)
              return editingTeam && isSelected ? (
                <TeamEditRow
                  key={idx}
                  team={team}
                  handleChange={handleChange}
                  handleSubmit={handleUpdateTeam}
                />
              ) : (
                <TeamItem
                  key={idx}
                  team={team}
                  handleClick={handleClick}
                  selectedIds={selectedIds}
                  handleSelect={handleSelect}
                />
              )
            })}

            {editing && (
              <TeamAddRow
                team={team}
                handleChange={handleChange}
                handleSubmit={handleCreateTeam}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default TeamList

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.palette.background.table,
  },
  caption: {
    fontWeight: 600,
  },
  bulkActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bulkActionButton: {
    marginLeft: theme.spacing(1),
  },
}))
