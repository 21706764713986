import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useSearch, useSelected, useUsers, useAlerts } from "hooks"
import { makeStyles, Button } from "@material-ui/core"
import { Search } from "@material-ui/icons"
import { Autosuggest, Page, LeftPanel, RightPanel, Subheader } from "components"
import SearchResults from "containers/search/SearchResults"
import SearchForm from "containers/search/SearchForm"
import { SEARCH_BULK_ACTIONS } from "lib/constants"
import { useLocation } from "react-router"
import queryString from "query-string"

const SearchContainer = ({ ...props }) => {
  const history = useHistory()
  const classes = useStyles()

  const DEFAULT_QUERY = {
    keywords: '',
    country: '',
    position: '',
    age_min: '',
    age_max: '',
    height_min: '',
    height_max: '',
    weight_min: '',
    weight_max: ''
  }

  const location = useLocation()
  const searchParams = queryString.parse(location.search)

  const { showAlertError, showAlertSuccess } = useAlerts()

  const [activeAction, setActiveAction] = useState()
  const [query, setQuery] = useState({
    keywords: searchParams.keywords || null,
    page: 1,
    perPage: 20,
  })

  const { selected, selectedIds, handleSelect, handleClear } = useSelected()

  const {
    isLoading,
    users,
    findUsers,
    handleSort,
    sortKey,
    sortDirection,
    page,
    numPages,
    paginate,
  } = useSearch({})

  const { isLoading: isUserLoading, addFavorites, removeFavorites } = useUsers({})  

  const handleQueryChange = (ev) => {
    const { name, value } = ev.target
    setQuery({
      ...query,
      [name]: value,
    })
  }

  const handleSearch = () => {
    findUsers(query)
  }

  const handleResetFilters = () => {
    setQuery(DEFAULT_QUERY)
  }

  const handleBulkAction = async () => {
    if (!activeAction) {
      showAlertError("Please select a bulk action")
      return
    }

    if (!selectedIds?.length > 0) {
      showAlertError("Select at least 1 player")
      return
    }
    let resp
    switch (activeAction) {
      case "add_favorites":
        resp = await addFavorites(selectedIds)
        showAlertSuccess("Added to listed players")
        return
      case "remove_favorites":
        await removeFavorites(selectedIds)
        showAlertSuccess("Removed from listed players")
        return
    }
  }

  const handleProfileClick = (user) => {
    history.push(`/players/${user.id}`)
  }

  useEffect(() => {
    findUsers(query)
  }, [])

  return (
    <>
      <Subheader
        icon={Search}
        title={"Advanced Search"}
        page={page}
        numPages={numPages}
        handleChangePage={paginate}
        actions={
          <div className={classes.bulkActions}>
            <Autosuggest
              value={activeAction}
              options={SEARCH_BULK_ACTIONS}
              placeholder={"Bulk actions"}
              handleChange={(ev) => setActiveAction(ev.target.value)}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleBulkAction}
              className={classes.bulkActionButton}
            >
              Apply
            </Button>
          </div>
        }
      />
      <Page>
        <LeftPanel>
          <SearchForm
            query={query}
            handleChange={handleQueryChange}
            handleSearch={handleSearch}
            handleResetFilters={handleResetFilters}
          />
        </LeftPanel>
        <RightPanel>
          <div className={classes.userList}>
            <SearchResults
              users={users}
              sortKey={sortKey}
              sortDirection={sortDirection}
              handleSort={handleSort}
              handleClick={handleProfileClick}
              selectedIds={selectedIds}
              handleSelect={handleSelect}
            />
          </div>
        </RightPanel>
      </Page>
    </>
  )
}

export default SearchContainer

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  userList: {
    padding: theme.spacing(0),
  },
  bulkActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  bulkActionButton: {
    marginLeft: theme.spacing(1),
  },
}))
