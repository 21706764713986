import React from "react"
import { makeStyles } from "@material-ui/core"

const TableHeader = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.root}>{children}</div>
}

export default TableHeader

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
}))
