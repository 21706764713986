import React from "react"
import api from "api"
import { useAlerts, useLoaders, useResource } from "hooks"
import { validateTeam } from "validations/teams"

const useTeams = ({ id, ...props }) => {
  const { showLoading, hideLoading } = useLoaders()
  const { showAlertError, showAlertSuccess } = useAlerts()

  const {
    resourceId: teamId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    resource: team,
    resources: teams,
    findOne: findTeam,
    findMany: findTeams,
    update: updateTeam,
    create: createTeam,
    save: saveTeam,
    destroy: deleteTeam,
    handleChange,
    handleChangePage,
    reload: reloadTeam,
    reloadMany: reloadTeams,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    setResource: setTeam,
  } = useResource({
    id: id,
    url: `/api/v1/scout/teams`,
    name: "team",
  })

  const deleteTeams = async (teamIds) => {
    try {
      showLoading()
      const resp = await api.post(`/api/v1/scout/teams/delete_many`, {
        team_ids: teamIds,
      })
    } catch (e) {
      console.log(e)
    } finally {
      hideLoading()
    }
  }

  return {
    teamId,
    isLoading,
    isLoaded,
    isEmpty,
    isEditing,
    isValid,
    team,
    teams,
    findTeam,
    findTeams,
    saveTeam,
    updateTeam,
    createTeam,
    deleteTeam,
    deleteTeams,
    handleChange,
    handleChangePage,
    query,
    page,
    paginate,
    numPages,
    perPage,
    totalCount,
    sortKey,
    sortDirection,
    handleSort,
    reloadTeam,
    reloadTeams,
    setTeam,
  }
}

export default useTeams
