import React from "react"
import { useHistory } from "react-router-dom"
import { SortableTableHeader } from "components"
import {
  Paper,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
} from "@material-ui/core"
import SearchItem from "containers/search/SearchItem"

const TABLE_HEADERS = [
  {
    value: "name",
    label: "Full Name",
    sortable: true,
    hidden: false,
  },
  {
    value: "country",
    label: "Country",
    sortable: true,
    hidden: false,
  },
  {
    value: "positions",
    label: "Position",
    sortable: true,
    hidden: false,
  },
  {
    value: "preferred_foot",
    label: "Foot",
    sortable: true,
    hidden: false,
  },
  {
    value: "dob",
    label: "Date of Birth",
    sortable: true,
    hidden: false,
  },
  {
    value: "height",
    label: "Height",
    sortable: true,
    hidden: false,
  },
  {
    value: "weight",
    label: "Weight",
    sortable: true,
    hidden: false,
  },
  {
    value: "total_score",
    label: "Training",
    sortable: true,
    hidden: false,
  },
]

const SearchResults = ({
  users,
  handleSort,
  sortKey,
  sortDirection,
  handleClick,
  selectedIds,
  handleSelect,
  ...props
}) => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <div>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableHeaderRow}>
              {TABLE_HEADERS.filter((h) => !h.hidden).map((header, idx) => (
                <SortableTableHeader
                  key={idx}
                  handleSort={handleSort}
                  value={`users.${header.value}`}
                  sortable={header.sortable}
                  sortKey={sortKey}
                  sortDirection={sortDirection}
                >
                  {header.label}
                </SortableTableHeader>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, idx) => (
              <SearchItem
                key={idx}
                user={user}
                handleClick={handleClick}
                selectedIds={selectedIds}
                handleSelect={handleSelect}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default SearchResults

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: 600,
  },
  table: {},
  tableHeaderRow: {
    backgroundColor: theme.palette.background.table,
  },
}))
