import React, { useEffect, useState } from "react"
import { useHistory } from "react-router"
import { useClubs, useAlerts } from "hooks"
import { makeStyles, Button } from "@material-ui/core"
import { ImageUploader, Page, LeftPanel, RightPanel, Subheader } from "components"
import TeamContainer from "containers/teams/TeamContainer"
import ClubDetails from "containers/clubs/ClubDetails"
import ClubForm from "containers/clubs/ClubForm"
import { validateClub } from "validations/clubs"
import PlayersModal from "containers/players/PlayersModal"
import PendingPlayersModal from "containers/players/PendingPlayersModal"
import Shield from "assets/icons/shield.svg"

const ClubShow = ({ ...props }) => {
  const history = useHistory()
  const classes = useStyles()

  const [editing, setEditing] = useState(false)
  const [notifications, setNotifications] = useState({})
  const [showPendingApprovals, setShowPendingApprovals] = useState(false)

  const [showPlayersModal, setShowPlayersModal] = useState(false)
  const [activeTeam, setActiveTeam] = useState(null)

  const {
    isLoading,
    club,
    findClub,
    findNotifications,
    handleChange,
    updateClub,
    uploadFile,
    deleteFile,
    page,
    numPages,
    paginate,
  } = useClubs({
    id: "me",
  })

  const { showAlertSuccess, showAlertError } = useAlerts()

  const handleTeamClick = (team) => {
    setActiveTeam(team)
    setShowPlayersModal(true)
  }

  const handleEditClick = () => {
    setEditing(!editing)
  }

  const handleSubmit = async () => {
    let valid = validateClub(club)
    if (valid.isValid) {
      const resp = await updateClub(club)
      showAlertSuccess("Club details saved.")
      setEditing(false)
    } else {
      valid.messages.map((m) => showAlertError(m))
    }
  }

  const handleNotifications = async () => {
    const resp = await findNotifications()
    if (resp?.pending_approvals > 0) {
      setNotifications(resp)
    } else {
      setNotifications(null)
    }
  }

  const handlePendingApprovalsClick = () => {
    setShowPendingApprovals(!showPendingApprovals)
  }

  const onCompleteSubmitApprovals = async () => {
    setShowPendingApprovals(false)
    handleNotifications()
  }

  const handleUpload = async (file, type) => {
    await uploadFile(file, type)
    findClub("me")
  }

  const handleDelete = async (type) => {
    await deleteFile(type)
    findClub("me")
  }

  useEffect(() => {
    handleNotifications()
  }, [])

  useEffect(() => {
    findClub("me")
  }, [])

  return (
    <>
      <Subheader
        imgIcon={Shield}
        notifications={notifications}
        handlePendingApprovalsClick={handlePendingApprovalsClick}
        title={isLoading ? "Loading..." : club.name}
        secondaryActions={
          <div className={classes.editButton}>
            <Button variant="contained" color="secondary" onClick={handleEditClick}>
              Edit Club
            </Button>
          </div>
        }
      />
      <Page>
        <LeftPanel>
          {editing ? (
            <div>
              <center>
                <ImageUploader
                  imageUrl={club.image_url}
                  handleUpload={(file) => handleUpload(file, "image_url")}
                  handleDelete={() => handleDelete("image_url")}
                />
              </center>
              <ClubForm
                club={club}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </div>
          ) : (
            <ClubDetails club={club} />
          )}
        </LeftPanel>
        <RightPanel>
          <TeamContainer
            teams={club.teams}
            editing={editing}
            setEditing={setEditing}
            handleClick={handleTeamClick}
          />
        </RightPanel>
      </Page>
      <PendingPlayersModal
        open={showPendingApprovals}
        club={club}
        onComplete={onCompleteSubmitApprovals}
        handleClose={() => setShowPendingApprovals(false)}
      />
      <PlayersModal
        open={showPlayersModal}
        team={activeTeam}
        club={club}
        page={page}
        numPages={numPages}
        handleChangePage={paginate}
        handleClose={() => setShowPlayersModal(false)}
      />
    </>
  )
}

export default ClubShow

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  editButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  editIcon: {
    height: 20,
    width: 20,
  },
}))
