import React from "react"
import { CircularProgress, makeStyles } from "@material-ui/core"
import clsx from "clsx"

function ButtonLoader() {
  const classes = useStyles()
  return <CircularProgress size={20} className={classes.loader} />
}

export default ButtonLoader

const useStyles = makeStyles((theme) => ({
  loader: {
    color: theme.palette.common.white,
  },
}))
