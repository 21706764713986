import React from "react"
import { IconButton, makeStyles } from "@material-ui/core"
import { ChevronLeft, ChevronRight, DoubleArrow } from "@material-ui/icons"
import KeyboardDoubleArrowLeft from "assets/icons/double_arrow_left.svg"
import KeyboardDoubleArrowRight from "assets/icons/double_arrow_right.svg"

const Pagination = ({ page, numPages, handleChangePage }) => {
  const classes = useStyles()

  const handleNextPage = () => {
    let nextPage = Math.min(page + 1, numPages)
    handleChangePage(nextPage)
  }

  const handlePrevPage = () => {
    let prevPage = Math.max(page - 1, 1)
    handleChangePage(prevPage)
  }

  const handleLastPage = () => {
    handleChangePage(numPages)
  }

  const handleFirstPage = () => {
    handleChangePage(1)
  }

  return (
    <div className={classes.root}>
      <IconButton size="small" onClick={handleFirstPage}>
        <img src={KeyboardDoubleArrowLeft} height={24} width="auto" />
      </IconButton>
      <IconButton size="small" onClick={handlePrevPage}>
        <ChevronLeft />
      </IconButton>
      <span>
        {page} of {numPages}
      </span>
      <IconButton size="small" onClick={handleNextPage}>
        <ChevronRight />
      </IconButton>
      <IconButton size="small" onClick={handleLastPage}>
        <img src={KeyboardDoubleArrowRight} height={24} width="auto" />
      </IconButton>
    </div>
  )
}

export default Pagination

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    alignItems: "center",
  },
}))
