import React, { useEffect } from "react"
import { useVideo, useVideos } from "hooks"
import { Grid, Typography, makeStyles } from "@material-ui/core"
import VideoItem from "containers/videos/VideoItem"
import { VideoModal } from "components"

const VideoGallery = ({ videos, renderUserName, ...props }) => {
  const classes = useStyles()

  const { activeVideo, setActiveVideo, playVideo, stopVideo, videoOpen } = useVideo()

  const handleVideoClick = (video) => {    
    playVideo(video)
  }

  return (
    <div>
      <Grid container spacing={1}>
        {videos &&
          videos.map((video, i) => (
            <Grid item xs={12} sm={6} md={3} xl={2} key={i}>
              <VideoItem 
                renderUserName={renderUserName} 
                video={video} 
                handleClick={handleVideoClick} 
              />
            </Grid>
          ))}
      </Grid>
      { activeVideo && (
      <VideoModal 
        video={activeVideo}
        open={videoOpen} 
        src={activeVideo?.url} 
        handleClose={stopVideo}         
      />
      )}
    </div>
  )
}

export default VideoGallery

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    margin: theme.spacing(1, 0),
  },
  title: {},
}))
