import React from "react"
import { useResponsive } from "hooks"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import clsx from "clsx"

const Modal = ({
  open,
  handleClose,
  icon,
  title,
  subtitle,
  actions: buttons,
  children,
  maxWidth,
  fullScreen,
  padding = 0,
  className,
}) => {
  const classes = useStyles({
    padding: padding,
  })

  const { isMobile } = useResponsive()
  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth={maxWidth || "sm"}
      fullScreen={isMobile || fullScreen === true ? true : false}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        className={clsx(className, classes.dialogTitle)}
        onClose={handleClose}
      >
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton
              size="small"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <Close className={classes.closeIcon} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <div className={classes.dialogContent}>
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        <div>{children}</div>
      </div>
      {buttons && <DialogActions>{buttons}</DialogActions>}
    </Dialog>
  )
}

export default Modal

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.background.main,
  },
  dialog: {},
  dialogTitle: {
    padding: theme.spacing(1, 2),
    width: "100%",
  },
  dialogContent: {
    padding: (props) => theme.spacing(props.padding),
  },
  closeIcon: {
    color: theme.palette.background.main,
  },
  closeButton: {
    margin: 0,
  },
}))
