import axios from "axios"
import { API_URL } from "config/secrets"

const headers = { "Content-Type": "application/json" }

// on page reload, get the token from localStorage
let token = localStorage.getItem("token")
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
}

const api = axios.create({
  baseURL: API_URL,
  headers,
  timeout: 200000,
})

api.interceptors.response.use(
  (resp) => Promise.resolve(resp.data),
  (error) => Promise.reject(error.response)
)

export default api
