import React, { useEffect, useState } from "react"
import { useUsers } from "hooks"
import { Box, Typography, makeStyles } from "@material-ui/core"
import { Explore } from "@material-ui/icons"
import { Pagination, TextInput, RightPanel, Subheader } from "components"
import DiscoverPlayerList from "containers/discover/DiscoverPlayerList"
import VideoSearchModal from "containers/videos/VideoSearchModal"

const DiscoverContainer = ({ ...props }) => {
  const classes = useStyles()

  const [keywords, setKeywords] = useState("")
  const [showVideos, setShowVideos] = useState(false)

  const handleKeywordChange = (e) => {
    setKeywords(e.target.value)
  }

  const handleKeyPress = (e) => {
    if (e.code === "Enter") {
      setShowVideos(true)
    }
  }

  const {
    isLoading,
    users,
    findUsers,
    handleSort,
    sortKey,
    sortDirection,
    page,
    numPages,
    paginate,
  } = useUsers({})

  useEffect(() => {
    findUsers({
      "users.featured": true,
      sort_key: sortKey,
      sort_direction: sortDirection,
    })
  }, [sortKey, sortDirection])

  return (
    <>
      <Subheader icon={Explore} title={"Discover"} />
      <RightPanel>
        <div className={classes.content}>
          <div className={classes.titleBar}>
            <Typography variant="h5" className={classes.title}>
              Search Videos
            </Typography>
          </div>
          <Box mx={2} width={280}>
            <TextInput
              value={keywords}
              placeholder="Search Keyword(s)"
              handleChange={handleKeywordChange}
              name="keywords"
              onKeyPress={handleKeyPress}
            />
          </Box>
          <div className={classes.titleBar}>
            <Typography variant="h5" className={classes.title}>
              Players To Watch
            </Typography>
            <Pagination
              page={page}
              numPages={numPages}
              handleChangePage={paginate}
            />
          </div>
          <DiscoverPlayerList
            users={users}
            handleSort={handleSort}
            sortKey={sortKey}
            sortDirection={sortDirection}
          />
        </div>
      </RightPanel>
      <VideoSearchModal
        keywords={keywords}
        open={showVideos}
        handleClose={() => setShowVideos(false)}
      />
    </>
  )
}

export default DiscoverContainer

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  titleBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(2),
  },
}))
